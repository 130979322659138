import React, { Component } from 'react'
import Select from 'react-select'
import { navigate } from 'gatsby'
import { Search } from '../../icons'

class SearchForm extends Component {

  state = {
    search: '',
    position: null,
    location: null,
  }

  handleSelectChange = (option, field) => {
    let state = {}
    switch (field?.action) {
      case 'select-option':
        state[field.name] = option?.value === null ? '' : option
        this.setState(state, this.updateList)
        break
      default:
        let { name, value } = option.currentTarget
        state[name] = value
        this.setState(state, this.updateList)
        break
    }
  }

  updateList = () => {
    const { list, set } = this.props
    let { search, position, location } = this.state

    let results = {}

    if (list) {
      results = list.filter((el, i) => {
        const { title, jobTitle, woollahra, roseBay } = el
        let searchObject = {
          'title': title,
          'role': jobTitle,
        }
        if (position && position.value !== jobTitle) return false
        if (location && location.value !== 'Woollahra' && !woollahra) return false
        if (location && location.value !== 'Rose Bay' && !roseBay) return false
        if (search && Object.keys(searchObject).map(key => searchObject[key]).join(' ').toLowerCase().indexOf(search.toLowerCase()) === -1) return false
        return true
      })
    }
    set(results)
  }

  _selectChange = option => {
    if (option.value === this.props.type) return
    navigate(`/${option.value}/`)
  }

  render() {
    let { position, location } = this.state
    const { positions, offices } = this.props

    const positionsList = positions?.map((el) => ({
      label: el,
      value: el,
    }))

    const officesList = offices?.map((el) => ({
      label: el,
      value: el,
    }))
    
    return (
      <section className='search search--team'>
        <div className="search__inner">
          <form className='search__form form'>
            <div className="form__row form__row--search">
              <input className="form__input" type="text" name="search" value={this.state.search} placeholder='Keyword Search' onChange={this.handleSelectChange} />
              <button type='submit'>
                <Search color='#21242F' />
              </button>
            </div>
            <div className="form__row form__row--dropdowns">
              { officesList?.length > 0 &&
                <div className='form__select form__select--location'>
                  <Select
                    options={officesList}
                    onChange={this.handleSelectChange}
                    openMenuOnFocus={true}
                    id="location"
                    name="location"
                    value={location}
                    placeholder="Location: All"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    ref={node => this.location = node}
                  />
                </div>
              }
              { positionsList?.length > 0 &&
                <div className='form__select form__select--position'>
                  <Select
                    options={positionsList}
                    onChange={this.handleSelectChange}
                    openMenuOnFocus={true}
                    id="position"
                    name="position"
                    value={position}
                    placeholder="Expertise: All"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    ref={node => this.position = node}
                  />
                </div>
              }
            </div>
          </form>
        </div>
      </section>
    )
  }
}

SearchForm.defaultProps = {
  list: [],
  set: () => {},
  positions: [],
}

export default SearchForm