import React from 'react'
import { MediaItem } from '../../../plugins/gatsby-plugin-atollon'
import PropertyLink from '../../utils/property-link'
import moment from 'moment'

const PropertyCard = (props) => {

  let inspections = []
  // sort inspections by date
  props.inspections?.length > 0 && props.inspections.sort((a, b) => {
    const aDate = moment(a.startDateTimeUtc, 'YYYYMMDDTHHmmss')
    const bDate = moment(b.startDateTimeUtc, 'YYYYMMDDTHHmmss')
    if (aDate.isBefore(bDate)) return -1
    if (aDate.isAfter(bDate)) return 1
    return 0
  })
  // filter out inspections that have same remoteId
  inspections = props.inspections?.length > 0 && props.inspections.filter((el, i) => {
    return props.inspections.map(el => el.startDateTimeUtc).indexOf(el.startDateTimeUtc) === i
  })
  inspections = inspections?.length > 0 && inspections.map((el, i) => {
    if (moment(el.startDateTimeUtc).add(0, 'days').isBefore(new Date())) return false
    return (
      `Open ${moment(el.startDateTimeUtc).format('ddd D MMM')}`
    )
  }).filter(el => el)

  const nextInspection = inspections?.length > 0 && inspections.shift()

  return (
    <PropertyLink to={ props.permalink } className={`card card--property ${props.blur ? 'card--blur' : ''}`} animate {...props.cardProps}>
      <div data-sal='slide-up' data-sal-duration='1000'>
        <div className='card__image'>
          { props.for === 'sold' && <span className='card__tag'>{props.for}</span> }
          { props.for === 'leased' && props.market === 'commercial' && <span className='card__tag'>{props.for}</span> }

          { nextInspection && <span className='card__tag'>{ nextInspection }</span> }

          {props.images.length > 0 && <MediaItem item={props.images[0].primary[0]} />}
        </div>
        <div className='card__content'>
          <h4>{ props.address }</h4>
          <div className='card__meta'>
            { props.market !== 'commercial' &&
              <p>
                { props.beds > 0 && <span>{ `${ props.beds } Bed` }</span> }
                { props.baths > 0 && <span>{ ` — ${ props.baths } Bath` }</span> }
                { props.allCarSpaces > 0 && <span>{ ` — ${ props.allCarSpaces } Car` }</span> }
              </p>
            }
            { props.market === 'commercial' &&
              <p><span>{props.floorArea.replace('sqm', 'm²')}{props.allCarSpaces > 0 ? ` — ${props.allCarSpaces} Car` : ''}</span></p>
            }
            {( props.for === 'lease' && props.market !== 'commercial' ) && <p>${ props.priceSearch } pw</p> }
          </div>
        </div>
      </div>
    </PropertyLink>
  )
}

export default PropertyCard
