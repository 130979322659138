import React, { Component } from 'react'
import Select from 'react-select'
import { Search } from './icons'

const rentRange = [
  { label: "Show All", value: null },
  { label: "< $500", value: { min: 0, max: 499 }},
  { label: "$500 - $1000", value: { min: 500, max: 1000 }},
  { label: "$1001 - $1500", value: { min: 1001, max: 1500 }},
  { label: "$1501 - $2000", value: { min: 1501, max: 2000 }},
  { label: "$2001 - $2500", value: { min: 2001, max: 2500 }},
  { label: "$2501 - $3000", value: { min: 2501, max: 3000 }},
  { label: "$3001+", value: { min: 3001, max: 100000000 }},
]

const landRange = [
  { label: "Show All", value: null },
  { label: "< 200M²", value: { min: 0, max: 199 }},
  { label: "200M² - 300M²", value: { min:200, max: 299 }},
  { label: "300M² - 500M²", value: { min: 300, max: 499 }},
  { label: "500M² - 1000M²", value: { min: 500, max: 999 }},
  { label: "> 1000M² ", value: { min: 1000, max: 100000000 }},
] 

const priceRange = [
  { label: "Show All", value: null },
  { label: "< $1M", value: { min: 0, max: 1000000 }},
  { label: "$1M - $2M", value: { min: 1000000, max: 1999999 }},
  { label: "$2M - $3M", value: { min: 2000000, max: 2999999 }},
  { label: "$3M - $4M", value: { min: 3000000, max: 3999999 }},
  { label: "$4M - $5M", value: { min: 4000000, max: 4999999 }},
  { label: "$5M - $6M", value: { min: 5000000, max: 5999999 }},
  { label: "$6M+", value: { min: 6000000, max: 100000000 }},
]

const commercialType = [
  { label: "Show All", value: null },
  { label: "For Sale", value: ['sale']},
  { label: "Sold", value: ['sold']},
]

const commercialLeaseType = [
  { label: "Show All", value: null },
  { label: "For Lease", value: ['lease']},
  { label: "Leased", value: ['leased']},
]

const bed_bath_park = [
  { label: "Show All", value: null },
  { label: "1+", value: 1},
  { label: "2+", value: 2},
  { label: "3+", value: 3},
  { label: "4+", value: 4},
  { label: "5+", value: 5},
  { label: "6+", value: 6}
]

class SearchForm extends Component {

  state = {
    search: '',
    bedrooms: null,
    commercial_type: null,
    price_range: null,
    land_range: null,
    sub: null,
  }

  scrollToResults = (event) => {
    event.preventDefault()
    const cont = document.querySelector('section.search')
    const rect = cont.getBoundingClientRect()
    
    window.scrollTo({
      top: rect.bottom - 30,
      left: 0,
      behavior: 'smooth'
    });
  }

  handleSelectChange = (option, field) => {
    let state = {}
    switch (field?.action) {
      case 'select-option':
        state[field.name] = option?.value === null ? '' : option
        this.setState(state, this.updateList)
        break
      default:
        let { name, value } = option.currentTarget
        state[name] = value
        this.setState(state, this.updateList)
        break
    }
  }

  updateList = () => {
    const { list, set, type } = this.props
    const { search, bedrooms, price_range, sub, land_range, commercial_type } = this.state

    let results = {}

    if (list) {
      results = list.filter((el, i) => {
        const { beds, priceSearch, landArea, suburb, address } = el

        let landAreaValue = landArea ? landArea.replace('sqm', '') : 0

        if (bedrooms?.value && (parseInt(beds) < bedrooms.value)) return false
        
        if (commercial_type?.value === 'lease') {
          if (commercial_type?.value && !el.for.includes('lease')) return false
        } else if (commercial_type?.value === 'leased') {
          if (commercial_type?.value && !el.for.includes('leased')) return false
        } else {
          if (commercial_type?.value && !el.for.includes(commercial_type.value)) return false
        }
        
        if (price_range?.value && parseInt(priceSearch) < price_range.value.min) return false
        if (price_range?.value && parseInt(priceSearch) > price_range.value.max) return false

        if (land_range?.value && parseInt(landAreaValue) < land_range.value.min) return false
        if (land_range?.value && parseInt(landAreaValue) > land_range.value.max) return false
        
        if (sub?.value && sub.value !== suburb?.title) return false
        if (search && address.replace(/,/g, '').toLowerCase().indexOf(search.toLowerCase()) === -1 ) return false
        
        return true
      })
    }
    set(results)
  }

  render() {
    let { bedrooms, price_range, sub, commercial_type, land_range } = this.state
    let { type, suburbs } = this.props

    let suburbsList = [{ label: "Show All", value: null }]

    suburbs?.map((el, i) => {
      suburbsList.push({
        label: el,
        value: el,
      })
      return true
    })
    
    return (
      <section className='search'>
        <div className="search__inner">
          <form className='search__form form'>
            <div className="form__row form__row--search">
              <input className="form__input" type="text" name="search" value={this.state.search} placeholder='Keyword Search' onChange={this.handleSelectChange} />
              <button type='button' onClick={this.scrollToResults}>
                <Search color='#21242F' />
              </button>
            </div>
            <div className="form__row form__row--dropdowns">
              { (type !== 'commercial' && type !== 'lease_commercial') &&
                <>
                  <div className='form__select form__select--bed'>
                    <Select
                      options={bed_bath_park}
                      onChange={this.handleSelectChange}
                      openMenuOnFocus={true}
                      id="bedrooms"
                      name="bedrooms"
                      value={bedrooms}
                      placeholder="Bed"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      ref={node => this.bedrooms = node}
                    />
                  </div>
                  <div className='form__select form__select--price'>
                    <Select
                      options={type === 'lease' ? rentRange : priceRange}
                      onChange={this.handleSelectChange}
                      openMenuOnFocus={true}
                      id="price_range"
                      name="price_range"
                      value={price_range}
                      placeholder="Price Range"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      ref={node => this.price_range = node}
                    />
                  </div>
                </>
              }
              { (type === 'commercial' || type === 'lease_commercial') &&
                <>
                  <div className='form__select form__select--bed'>
                    <Select
                      options={type === 'lease_commercial' ? commercialLeaseType  : commercialType}
                      onChange={this.handleSelectChange}
                      openMenuOnFocus={true}
                      id="commercial_type"
                      name="commercial_type"
                      value={commercial_type}
                      placeholder="Type"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      ref={node => this.commercial_type = node}
                    />
                  </div>
                  <div className='form__select form__select--price'>
                    <Select
                      options={landRange}
                      onChange={this.handleSelectChange}
                      openMenuOnFocus={true}
                      id="land_range"
                      name="land_range"
                      value={land_range}
                      placeholder="Building Size"
                      className="react-select-container"
                      classNamePrefix="react-select"
                      ref={node => this.land_range = node}
                    />
                  </div>
                </>
              }
              { suburbsList && suburbsList.length > 0 &&
                <div className='form__select form__select--location'>
                  <Select
                    options={suburbsList}
                    onChange={this.handleSelectChange}
                    openMenuOnFocus={true}
                    id="sub"
                    name="sub"
                    value={sub}
                    placeholder="Location"
                    className="react-select-container"
                    classNamePrefix="react-select"
                    ref={node => this.location = node}
                  />
                </div>
              }
            </div>
          </form>
        </div>
      </section>
    )
  }
}

SearchForm.defaultProps = {
  list: [],
  set: () => {},
  type: 'buy',
  suburbs: [],
}

export default SearchForm