import React from 'react'
import { Asset, Link } from '../../../../plugins/gatsby-plugin-atollon'
import usePodcastsQuery from "../../../hooks/use-podcasts-query"
import parse from 'html-react-parser'
import { graphql } from 'gatsby'
import { Play } from '../../icons'

const PodcastArchive = ({ title, image }) => {
  // Data
  const podcasts = usePodcastsQuery()

  // State
  const [list, setList] = React.useState(podcasts)
  const [hover, setHover] = React.useState(false)

  return (
    <>
      <section className='media-archive'>
        <div className='media-archive__outer'>
          { title && <h1>{parse(title)}</h1> }
        </div>
        <div className='media-archive__inner podcast__inner'>
          <div className='podcast__image'>
            { image && <Asset asset={image} alt={title} /> }
          </div>
          <div className='podcast__items'>
            { list?.map((el, i) => {
              el.cardProps = {
                onMouseEnter: () => setHover(el.id),
                onMouseLeave: () => setHover(false),
              }
              el.blur = hover !== el.id && hover !== false
              let externalLink = el.link[0].link.length > 0 ? el.link[0].link[0].externalLink : false
              return (
                <div className='podcast__item'>
                  <p className='title'>{ el.episode && <span className='episode'>EPISODE {el.episode}</span>}<span>{parse(el.link[0].title)}</span></p>
                  <p className='listen-now'>Listen Now <Play color={`#21242F`} /></p>
                  { externalLink && <Link className='article__link' to={externalLink} /> }
                </div>
              ) 
            }) }
          </div>
        </div>
      </section>
    </>
    
  )
}

export default PodcastArchive

export const query = graphql`
fragment PodcastArchiveProps on DatoCmsPodcastArchive {
  ... on DatoCmsPodcastArchive {
    title
    image {
      ...InternalAssetProps
    }
  }
}
`
 