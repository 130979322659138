import React from 'react'
import parse from 'html-react-parser'
import TeamCard from '../../cards/team'
import FormComponent from '../../form';
import { graphql } from 'gatsby'
import { FormStepProvider } from '../../../../plugins/gatsby-plugin-atollon'

const AgentsEnquire = ({ agents, formTitle, projectName, form }) => (
  <FormStepProvider steps={agents?.map(agent => (
    {
      type: "emailTo",
      id: agent.originalId,
      field: "email"
    }
  ))}>
    <section className='agents-enquire' id='enquire'>
      <div className='agents-enquire__inner'>
        { formTitle && <h4 className='agents-enquire__title agents-enquire__title--mobile'>{ parse(formTitle) }</h4> }
        <div className='agents-enquire__agents'>
          { agents && agents.map((el, i) => (
            <TeamCard key={ i } { ...el } />
          )) }
        </div>
        <div className='agents-enquire__form form--dark'>
          { formTitle && <h4 className='agents-enquire__title agents-enquire__title--desktop'>{ parse(formTitle) }</h4> }
          <FormComponent property={projectName} form={form[0].form} />
        </div>
      </div>
    </section>
  </FormStepProvider>
)

export default AgentsEnquire

export const query = graphql`
fragment AgentsEnquireProps on DatoCmsAgentsEnquire {
  ... on DatoCmsAgentsEnquire {
    id
    projectName
    formTitle
    form {
      form {
        ...FormProps
      }
    }
    agents {
      originalId
      email
      jobTitle
      phone
      position
      title
      name
      id
      biography {
        value
      }
      profileImage {
        ...InternalAssetProps
      }
    }
  }
}
`