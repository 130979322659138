import React from 'react'
import {AtollonProvider} from "../../plugins/gatsby-plugin-atollon";
import layoutQuery from "../hooks/use-layout-query"
import podcastQuery from "../hooks/use-podcast-query"
import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = (props) => {

    const globalData = layoutQuery()
    const podcast = podcastQuery()
    const offices = globalData.datoCmsSiteOptionPage.office
    const { news } = globalData

    const [dark, setDark] = React.useState(false)

    React.useEffect(() => {
        document.documentElement.style.setProperty(`--primaryColor`, dark ? '#FFFFFF' : '#21242F');
        document.documentElement.style.setProperty(`--secondaryColor`, dark ? '#21242F' : '#FFFFFF');
        document.documentElement.style.setProperty(`--border`, dark ? '#545A68' : '#D9D8D9');
        document.documentElement.style.setProperty(`--property-header-bg`, dark ? '#545A68' : '#FFFFFF');
    }, [dark])

    const toggleTheme = (event) => {
        event.preventDefault()
        setDark(!dark)
    }

    return (
        <AtollonProvider {...props} globalData={globalData}>
            <Header pageContext={props.pageContext} news={news} podcast={podcast} />
            <main>
                { props.children }
            </main>
            <Footer toggleTheme={toggleTheme} offices={offices} pageContext={props.pageContext} />
        </AtollonProvider>
    )
}

export default Layout
