import React, { Component } from 'react'
import { hierarchicalMenu, Link, Asset } from "../../plugins/gatsby-plugin-atollon"
import { Icon, Logo } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,

    scrolled: false,
    headerShow: true,

    direction: 0,
    prevDirection: 0,
    prevScroll: 0,

    // Dropdowns
    sales: false,
    leasing: false,
    explore: false,

    cardHover: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  toggleScrolled = () => {
  }

  onScroll = (e) => {
    let { direction, prevDirection, prevScroll, headerShow, scrolled } = this.state 

    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 1

    // Make sure they scroll more than 5px
    if(Math.abs(prevScroll - curScroll) <= 5) return;

    if (curScroll > prevScroll) { 
      //scrolled up
      direction = 2;
    }
    else if (window.scrollY < prevScroll) { 
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      if (direction === 2 && curScroll > 120) { 
        headerShow = false
      }
      else if (direction === 1) {
        headerShow = true
      }
    }

    // Bottom of page
    if (window.innerHeight + curScroll >= document.body.scrollHeight) {
      headerShow = true
    }
    
    prevScroll = curScroll;

    requestAnimationFrame(() => {
      this.setState({ 
        scrolled, headerShow,  direction, prevDirection, prevScroll
      })
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  renderCards = () => {
    const { news, podcast } = this.props

    let cards = []

    const newsItem = news?.nodes.length && news?.nodes[0]
    let newsExternalLink = newsItem.link[0].link?.length > 0 ? newsItem.link[0].link[0].externalLink : false
    if (newsItem) {
      cards.push({
        image: newsItem.featuredImage && <Asset asset={newsItem.featuredImage} alt={newsItem.title} />,
        title: "In The Media",
        desc: newsItem.title,
        uri: newsExternalLink,
      })
    }

    const podcastItem = podcast?.nodes.length && podcast?.nodes[0]
    let podcastExternalLink = podcastItem.link[0].link?.length > 0 ? podcastItem.link[0].link[0].externalLink : false
    if (podcastItem) {
      cards.push({ 
        image: podcastItem.featuredImage && <Asset asset={podcastItem.featuredImage} alt={podcastItem.title} />,
        title: "Podcast: Thinking outside the box",
        desc: podcastItem.title,
        uri: podcastExternalLink,
      })
    }

    return cards.map(this.renderCard)
  }

  renderCard = ({ image, title, desc, uri }, i) => {
    let ch = this.state.cardHover
    const props = { 
      key: i,
      className: `card ${ch !== i && ch !== false ? 'card--blur' : ''}`,
      onMouseEnter: () => this.setState({ cardHover : i }),
      onMouseLeave: () => this.setState({ cardHover: false }),
    }

    return (
      <div {...props}>
        <div className='card__image'>
          { image }
        </div>
        <div className='card__content'>
          <h4>{ title }</h4>
          <p>{ desc }</p>
        </div>
        { uri && <Link className='card__link' to={ uri }></Link> }
      </div>
    )
  }

  render() {

    let { offCanvas, scrolled, headerShow, sales, leasing, explore } = this.state
    const { news, podcast } = this.props

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (!headerShow) headerClass += ' header--hide'
    if (offCanvas) headerClass += ' header--off-canvas-active'

    let headerProps ={
      className: headerClass,
      onMouseEnter: () => this.setState({ scrolled: true }),
      onMouseLeave: this.onScroll,
    }

    return (
      <>
        <header {...headerProps}>
          <div className='header__inner'>
            <Link to='/' title='TRG' className='header__logo' {...props}>
              <Icon color={`#21242F`}  />
              <Logo color={`#21242F`} />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li className='listings'>
                  <Link to='/sales/' {...props}>Sales</Link>
                  <div className='dropdown dropdown--listings'>
                    <ul>
                      <li><Link to='/sales/' {...props}>For Sale</Link></li>
                      <li><Link to='/commercial/' {...props}>Commercial</Link></li>
                      <li><Link to='/projects/' {...props}>TRG Projects</Link></li>
                      <li><Link to='/upcoming-auctions/' {...props}>Upcoming Auctions</Link></li>
                      <li><Link to='/upcoming-inspections/' {...props}>Upcoming Inspections</Link></li>
                    </ul>
                  </div>
                </li>
                <li className='listings'>
                  <Link to='/lease/' {...props}>Leasing</Link>
                  <div className='dropdown dropdown--listings'>
                    <ul>
                      <li><Link to='/lease/' {...props}>For Lease</Link></li>
                      <li><Link to='/commercial-lease/' {...props}>Commercial Lease</Link></li>
                    </ul>
                  </div>
                </li>
                <li><Link to='/team/' {...props}>Team</Link></li>
                <li>
                  <Link to='/media/' {...props}>Explore</Link>
                  <div className='dropdown'>
                    <div className='dropdown__inner'>
                      <div className='dropdown__menu'>
                        <ul>
                          <li><Link to='/media/' {...props}>Press</Link></li>
                          <li><Link to='/podcast/' {...props}>Podcast</Link></li>
                          <li><Link to='/media/?cat=video' {...props}>Video</Link></li>
                          <li><Link to='/media/?cat=real-estate-diaries' {...props}>Real Estate Diaries</Link></li>
                        </ul>
                      </div>
                      <div className='dropdown__content'>
                        { this.renderCards() } 
                      </div>
                    </div>
                  </div>
                </li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className='header__hamburger'>
              { offCanvas ? 'Close' : 'Menu' }
            </button>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li className={`listings ${sales ? 'active': ''}`}>
                  <button onClick={() => this.setState({ sales: !sales })}>Sales</button>
                  <div className={`dropdown ${sales ? 'active': ''}`}>
                    <ul>
                      <li><Link to='/sales/' {...props}>For Sale</Link></li>
                      <li><Link to='/commercial/' {...props}>Commercial Sale</Link></li>
                      <li><Link to='/projects/' {...props}>TRG Projects</Link></li>
                      <li><Link to='/upcoming-auctions/' {...props}>Upcoming Auctions</Link></li>
                      <li><Link to='/upcoming-inspections/' {...props}>Upcoming Inspections</Link></li>
                    </ul>
                  </div>
                </li>
                <li className={`listings ${leasing ? 'active': ''}`}>
                  <button onClick={() => this.setState({ leasing: !leasing })}>Leasing</button>
                  <div className={`dropdown ${leasing ? 'active': ''}`}>
                    <ul>
                      <li><Link to='/lease/' {...props}>For Lease</Link></li>
                      <li><Link to='/commercial-lease/' {...props}>Commercial Lease</Link></li>
                    </ul>
                  </div>
                </li>
                <li><Link to='/team/' {...props}>Team</Link></li>
                <li className={`explore ${explore ? 'active': ''}`}>
                  <button onClick={() => this.setState({ explore: !explore })}>Explore</button>
                  <div className={`dropdown ${explore ? 'active': ''}`}>
                    <div className='dropdown__inner'>
                      <div className='dropdown__menu'>
                        <ul>
                          <li><Link to='/media/?cat=news' {...props}>Press</Link></li>
                          <li><Link to='/podcast/' {...props}>Podcast</Link></li>
                          <li><Link to='/media/?cat=video' {...props}>Video</Link></li>
                          <li><Link to='/media/?cat=real-estate-diaries' {...props}>Real Estate Diaries</Link></li>
                        </ul>
                      </div>
                      <div className='dropdown__content'>
                        <div className='card'>
                          <div className='card__image'>
                            { news?.nodes.length && news?.nodes[0].featuredImage && <Asset asset={news.nodes[0].featuredImage} alt={news?.nodes[0].title} /> }
                          </div>
                          <div className='card__content'>
                            <h4>In The Media</h4>
                            <p>{ news?.nodes.length && news?.nodes[0].title }</p>
                          </div>
                        </div>
                        <div className='card'>
                          <div className='card__image'>
                            { podcast?.nodes.length && podcast?.nodes[0].featuredImage && <Asset asset={podcast.nodes[0].featuredImage} alt={podcast?.nodes[0].title} /> }
                          </div>
                          <div className='card__content'>
                            <h4>Podcast: Thinking outside the box</h4>
                            <p>{ podcast?.nodes.length && podcast?.nodes[0].title }</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li><Link to='/contact/' {...props}>Contact</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
