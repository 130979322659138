import React from 'react'

import usePostsQuery from "../../../hooks/use-posts-query"
import useLatestNewsQuery from "../../../hooks/use-latest-news-query"

import ArticleCard from '../../cards/article'
import SearchMedia from '../../media-search'
import parse from 'html-react-parser'
import { graphql } from 'gatsby'

const MediaArchive = ({ title }) => {
  // Data
  const posts = usePostsQuery()
  const news = useLatestNewsQuery()

  const latestIds = [...news.slice(0, 6).map(el => el.id)]

  // State
  const [category, setCategory] = React.useState('news')
  const [list, setList] = React.useState(news.filter(el => latestIds.indexOf(el.id) === -1));
  const [limit, setLimit] = React.useState(8);
  const [hover, setHover] = React.useState(false);

  // Ref
  const load = React.createRef();

  // Events
  let timeout = null
  const onScroll = () => {
    let position = load?.current?.getBoundingClientRect()
    if (!position) return
    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      if (!timeout) {
      //if (!this.timeout && this.state.length < this.state.list) {
        timeout = setTimeout(() => {
          setLimit(limit + 8)
          timeout = null
        }, 10)
      }
    } else {
      clearTimeout(timeout)
    }
  }

  // Update
  React.useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  return (
    <>
      <section className='media-archive'>
        <div className='media-archive__outer'>
          { title && <h1>{parse(title)}</h1> }
          <SearchMedia
            list={posts || []} 
            set={setList} 
            setCategory={setCategory}
          />
        </div>
        <div className={`media-archive__inner ${category !== 'news' ? 'hide' : ''}`}>
          <div className='media-archive__featured-item'>
            <ArticleCard {...news[0]} />
          </div>
          <div className='media-archive__featured-wrapper'>
            <div className='media-archive__left'>
              { news.slice(2, 6).map((el, i) => (
                <div className='article__wrapper' key={i}>
                  <ArticleCard type='left' {...el} />
                </div>
              ))}
            </div>
            <div className='media-archive__right'>
              <div className='article__wrapper'>
                <ArticleCard {...news[1]} />
              </div>
            </div>
          </div>
        </div>
        { category === 'video' &&
          <div className='media-archive__video-inner'>
            <h2>The digital age is upon us</h2>
            <div className='media-archive__video-content'>
              <p>TRG is reimagining the way we utilise video content to showcase and sell Sydney's most elite properties. We develop a creative strategy that is unique to every individual home. Our innate understanding of market trends and consumer demands, enables us to create innovative content to effectively communicate with our audience.</p>
              <p>Our strength lies in creating content that captures the essence of your home, aiding us in maximising the sell price.</p>
            </div>
            <div className='media-archive__video-content'>
              <p>We understand that it is more than just likes, but the ability to find qualified leads as a result of digitally marketing your property better than anyone else.</p>
              <p>We have one of the widest reaching social pages, both locally and internationally.<br />The Rubinstein Group</p>
            </div>
          </div>
        }
        <div className='media-archive__inner'>
          <div className='media-archive__list media-archive__list'>
            { list?.slice(0, limit).map((el, i) => {
              el.cardProps = {
                onMouseEnter: () => setHover(el.id),
                onMouseLeave: () => setHover(false),
              }
              el.blur = hover !== el.id && hover !== false
              return (<ArticleCard key={i} {...el} />) 
            }) }
          </div>
          { list?.length === 0 && <p className='media-archive__no-results'>No media found for your search.</p> }
        </div>
      </section>
      <div ref={load} />
    </>
    
  )
}

export default MediaArchive

export const query = graphql`
fragment MediaArchiveProps on DatoCmsMediaArchive {
  ... on DatoCmsMediaArchive {
    title
  }
}
`
