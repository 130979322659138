import { useStaticQuery, graphql } from "gatsby"

const usePodcastsQuery = () => {
  const { allDatoCmsArticle } = useStaticQuery(graphql`
    query Podcasts {
      allDatoCmsArticle(
        filter: {
          category: {
            title: {
              eq: "Podcast"
            }
          }
        }
        sort: { meta: { publishedAt: DESC } }
      )
      {
        nodes {
          id
          title
          meta {
            publishedAt(formatString: "ddd DD MMMM")
          }
          featuredImage {
            ...InternalAssetProps
          }
          category {
            title
          }
          episode
          link {
            link {
              ... on DatoCmsExternalLink {
                externalLink
              }
            }
            title
          }
        }
      }
    }
  `)

  return allDatoCmsArticle.nodes
}

export default usePodcastsQuery