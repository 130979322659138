import React, { Component } from 'react'
import { Arrow } from './icons'
import addToMailchimp from 'gatsby-plugin-mailchimp'

class NewsletterForm extends Component {
  state = {
    firstname: '',
    lastname: '',
    email: '',
    result: {},
    msg: 'Subscribe',
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // 2. via `async/await`
  handleSubmit = async (e) => {
    e.preventDefault()

    const result = await addToMailchimp(this.state.email, {
      FNAME: this.state.firstname,
      LNAME: this.state.lastname,
    })
    // I recommend setting `result` to React state
    // but you can do whatever you want
    this.setState({
      result: result.result,
      msg: result.msg
    })
  }

  render() {

    let props = {
      name: 'newsletter',
      className: "form form--newsletter",
      onSubmit: this.handleSubmit
    }

    if (this.state.result === 'success') {
      return (
        <p className='form form--newsletter form--success'>Thanks for subscribing.</p>
      )
    }

    return (
      <form {...props}>
        <div className="form__row">
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            onChange={this.handleChange}
            required
          />
          <button type="submit" className="btn">
            <Arrow color={`#21242F`} />
          </button>
        </div>
      </form>
    )
  }
}

export default NewsletterForm