import React from 'react'
import { graphql } from 'gatsby'
import useBuyProperties from "../../../hooks/use-buy-properties"
import useSoldProperties from "../../../hooks/use-sold-properties"
import useListingProperties from "../../../hooks/use-listing-properties"
import useLeaseProperties from "../../../hooks/use-lease-properties"
import useCommercialLeaseProperties from "../../../hooks/use-commercial-lease-properties"
import useCommercialProperties from "../../../hooks/use-commercial-properties"

import PropertyCard from '../../cards/property'
import SearchForm from '../../property-search'
import sal from 'sal.js'

const ListingArchive = ({ propertyType }) => {
  // Data
  const properties = {
    'buy': useBuyProperties(),
    'listings': useListingProperties(),
    'sold': useSoldProperties(),
    'lease': useLeaseProperties(),
    'commercial': useCommercialProperties(),
    'lease_commercial': useCommercialLeaseProperties(), 
  }[propertyType]
  // State
  const [list, setList] = React.useState(properties);
  const [limit, setLimit] = React.useState(15);
  const [hover, setHover] = React.useState(false);

  let suburbs = []
  properties?.forEach((el, i) => {
    el.suburb?.title && suburbs.push(el.suburb.title)
  })
  suburbs = suburbs.filter((v, i, a) => a.indexOf(v) === i)
  suburbs.sort()

  // Ref
  const load = React.createRef();

  // Events
  let timeout = null
  const onScroll = () => {
    let position = load?.current?.getBoundingClientRect()
    if (!position) return
    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      if (!timeout) {
      //if (!this.timeout && this.state.length < this.state.list) {
        timeout = setTimeout(() => {
          setLimit(limit + 6)
          timeout = null
        }, 10)
      }
    } else {
      clearTimeout(timeout)
    }
  }

  // Update
  React.useEffect(() => {
    sal()
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  return (
    <>
      <section className='listings-archive'>
        <div className='listings-archive__inner'>
          { propertyType === 'sold' && <h1>Recently Sold</h1> }
          { propertyType === 'buy' && <h1>For Sale</h1> }
          { propertyType === 'lease' && <h1>Lease</h1> }
          { propertyType === 'listings' && <h1>Sales</h1> }
          { propertyType === 'commercial' && <h1>Commercial Listings</h1> }
          <SearchForm
            list={properties || []} 
            set={setList} 
            type={propertyType}
            suburbs={suburbs}
          />
          <div className='listings-archive__list'>
            { list?.slice(0, limit).map((el) => {
              el.cardProps = {
                onMouseEnter: () => setHover(el.id),
                onMouseLeave: () => setHover(false),
              }
              el.blur = hover !== el.id && hover !== false
              return (<PropertyCard key={el.id} {...el} />)
            }) } 
          </div>
          { list?.length === 0 && <p className='listings-archive__no-results'>No listings at this time.</p> }
        </div>
      </section>
      <div ref={load} />
    </>
    
  )
}

export default ListingArchive

export const query = graphql`
fragment ListingArchiveProps on DatoCmsListingArchive {
  ... on DatoCmsListingArchive {
    propertyType
  }
}
`