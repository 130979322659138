/* eslint-disable react/jsx-props-no-spreading, react/prop-types, import/no-cycle */
import React from 'react';
import EmptyBlock from './blocks/empty-block';
import Landing from './blocks/landing';
import ImageCta from './blocks/image-cta'
import Office from './blocks/office'
import ProjectSelector from './blocks/project-selector'
import ProjectHeader from './blocks/project-header'
import LatestMedia from './blocks/latest-media'
import MediaArchive from './blocks/media-archive'
import PodcastArchive from './blocks/podcast-archive'
import TeamArchive from './blocks/team-archive'
import BasicPage from './blocks/basic-page'
import Gallery from './blocks/gallery'
import BasicContent from './blocks/basic-content'
import QuoteStat from './blocks/quote-stat'
import AgentsEnquire from './blocks/agents-enquire'
import ListingArchive from './blocks/listing-archive'
import ThankYou from './blocks/thank-you'
import UpcomingAuctions from './blocks/upcoming-auctions'
import UpcomingInspections from './blocks/upcoming-inspections'
import { useDebug, DebugPill } from "../../../plugins/gatsby-plugin-atollon";

/*
* Context for retaining render depth
* */
export const BlockRendererContext = React.createContext(-1);

export const RenderBlock = ({ __typename, ...props }) => {
    switch (__typename.replace('DatoCms', '')) {
        case 'Landing':
            return <Landing {...props} />;
        case 'ImageCta':
            return <ImageCta {...props} />;
        case 'Office':
            return <Office {...props} />;
        case 'ProjectSelector':
            return <ProjectSelector {...props} />;
        case 'TeamArchive':
            return <TeamArchive {...props} />;
        case 'ProjectHeader':
            return <ProjectHeader {...props} />;
        case 'Gallery':
            return <Gallery {...props} />;
        case 'MediaArchive':
            return <MediaArchive {...props} />;
        case 'LatestNews':
            return <LatestMedia {...props} />;
        case 'PodcastArchive':
            return <PodcastArchive {...props} />;
        case 'ListingArchive':
            return <ListingArchive {...props} />;
        case 'BasicContent':
            return <BasicContent {...props} />;
        case 'BasicPage':
            return <BasicPage {...props} />;
        case 'QuoteStat':
            return <QuoteStat {...props} />;
        case 'ThankYou':
            return <ThankYou {...props} />;
        case 'AgentsEnquire':
            return <AgentsEnquire {...props} />;
        case 'UpcomingAuction':
            return <UpcomingAuctions {...props} />;
        case 'UpcomingInspection':
            return <UpcomingInspections {...props} />;
        default:
            return <EmptyBlock>{__typename}</EmptyBlock>;
    }
};

const BlockRenderer = ({ data, className }) => {
    const { showBlockNames } = useDebug();
    const existingRenderContextDepth = React.useContext(BlockRendererContext);

    return (
        <BlockRendererContext.Provider value={existingRenderContextDepth + 1}>
            {data && data.map((block) => {
                const { __typename: blockType } = block;

                if (!blockType) {
                    throw new Error("You must source __typename on every blocks field.")
                }

                return (
                    <>
                        {showBlockNames && (
                            <DebugPill style={{
                                position: 'absolute',
                            }}
                            >
                                {blockType}
                            </DebugPill>
                        )}
                        <RenderBlock
                            key={block.id}
                            className={className}
                            {...block}
                        />
                    </>
                );
            })}
        </BlockRendererContext.Provider>
    );
};

export default BlockRenderer;
