import React, { Component } from 'react'
import { Link, Asset } from '../../../plugins/gatsby-plugin-atollon'
import { Arrow, Play } from '../icons'
import Plyr from '../plyr'
import Fade from 'react-reveal/Fade'
import parse from 'html-react-parser'

class ArticleCard extends Component {

  state = {
    videoLightbox: false
  }

  _getVideoID(url) {
    if (!url) return null
    let regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/
    let match = url.match(regExp)
    return match && match.length > 1 && match[1]
  }

  _renderVideoLightbox(source, category) {
    let active = this.state.videoLightbox

    return (
      <div className={`lightbox ${active ? 'active' : ''}`}>
        <div className='lightbox__inner'>
          <svg onClick={() => this.setState({ videoLightbox: false })} className='close' width='25px' height='25px' viewBox='0 0 25 25' version='1.1' xmlns='http://www.w3.org/2000/svg'>
            <g stroke='none' strokeWidth='1' fill='#FFFFFF' fillRule='evenodd'>
              <path d='M12.4893787,24.9492612 C5.60275148,24.9492612 1.0658141e-14,19.3531028 1.0658141e-14,12.4746158 C1.0658141e-14,5.59615839 5.60275148,-1.42108547e-14 12.4893787,-1.42108547e-14 C19.3760059,-1.42108547e-14 24.978787,5.59615839 24.978787,12.4746158 C24.978787,19.3531028 19.3760059,24.9492612 12.4893787,24.9492612 M12.4893787,1.02231087 C6.16715976,1.02231087 1.02352071,6.15986998 1.02352071,12.4746158 C1.02352071,18.7893913 6.16715976,23.9269504 12.4893787,23.9269504 C18.8115976,23.9269504 23.9552367,18.7893913 23.9552367,12.4746158 C23.9552367,6.15986998 18.8115976,1.02231087 12.4893787,1.02231087'></path>
              <g transform='translate(7.000000, 7.000000)'>
                <polygon points='11 0.70490208 10.2951656 0 5.49999993 4.79509779 0.704834305 0 0 0.70490208 4.7951655 5.49999987 0 10.2950977 0.704834305 11 5.49999993 6.20490195 10.2951656 11 11 10.2950977 6.20483437 5.49999987'></polygon>
              </g>
            </g>
          </svg>
          { active &&
            <div>{this._renderMedia(source, category, active)}</div>
          }
        </div>
      </div>
    )
  }

  _renderMedia(source, category, active) {
    let yt = this._getVideoID(source)
    if (category === 'Real Estate Diaries') {
      return (
        <Plyr
          ref='player'
          provider='html5'
          autoplay={true}
          url={source}
        />
      )
    }
    if (yt) {
      return (
        <Plyr
          type="youtube"
          ref='player'
          videoId={active ? yt : 'none'}
          autoplay={true}
          urls={{
            youtube: {
              api: `https://noembed.com/embed?url=${0}`
            }
          }}
        />
      )
    }
    return (
      <div className='lightbox__iframe'>
        <iframe src={source} title={category} />
      </div>
    )
  }

  render() {

    let { title, featuredImage, link, category, video, type, blur, cardProps } = this.props
    let categoryName = category?.title
    let externalLink = (link?.length > 0 && link[0].link?.length > 0) ? link[0].link[0].externalLink : false
    if (categoryName === 'Video' || categoryName === 'Real Estate Diaries') {
      link.title = 'Watch'
    }
    if (category === 'Podcast') link = 'Listen Now'
    if (category === 'Instagram') {
      title = '@trg.re'
      link[0].title = 'Instagram'
      externalLink = 'https://instagram.com/trg.re/'
    }
    if (categoryName === 'Video' || categoryName === 'Real Estate Diaries') {
      if (!video) video = { url: externalLink }
    }
    

    return (
      <div className='article__wrapper'>
        <Fade distance='40px' bottom>
          <div className={`article ${blur ? 'article--blur' : ''}`} {...cardProps}>
            <div className='article__image'>
              {featuredImage && <Asset asset={featuredImage} alt={title} />}
            </div>
            <div className='article__content'>
              <div className='article__title'>
                { title && <h4>{parse(title)}</h4> }
              </div>
              <div className={`article__info ${categoryName}`}>
                {externalLink && link[0].title &&
                  <Link to={externalLink}>
                    {categoryName === 'Podcast' ? 'Listen Now' : link[0].title}
                    {(categoryName === 'Video' || categoryName === 'Podcast' || categoryName === 'Real Estate Diaries') && <Play color={`#21242F`} />}
                    {(categoryName === 'News' || categoryName === 'Instagram') && <Arrow color={`#21242F`} />}
                  </Link>
                }
              </div>
            </div>
            {(categoryName === 'Video' || categoryName === 'Real Estate Diaries') && <button type='button' className='article__link' onClick={() => this.setState({ videoLightbox: true })} aria-label="Open Video" />}
            {categoryName !== 'Video' && categoryName !== 'Real Estate Diaries' && externalLink && <Link className='article__link' to={externalLink} />}
          </div>
        </Fade>
        {video?.url && this._renderVideoLightbox(video.url, categoryName)}
      </div>
    )
  }
}

export default ArticleCard