import React, { Component } from 'react'

class LoadingScreen extends Component {

  state = {    
    scale: 1,
    hide: false,
  }

  constructor(props) {
    super(props)
    this.screen = React.createRef()
  }

  componentDidMount() {
    this.animate()
  }

  animate = () => {
    if (this.props.zoom) {
      return setTimeout(() => {
        this.setState({
          scale: 12,
        }, () => {
          setTimeout(() => {
            this.setState({ hide: true })
          }, 1400)
        })
      }, 800)
    }

    setTimeout(() => {
      this.setState({ hide: true })
    }, 1000)
  }

  render() {

    let { hide } = this.state

    return (
      <>
        <section className={`loading-screen ${hide ? 'hide' : ''}`} ref={this.screen}>
          <div className={`loading-screen__sticky`} style={{transform: `scale(${this.state.scale})`}}>
            { this.props.children }
          </div>
        </section>
      </>
    )
  }
}

export default LoadingScreen
