import useBuyProperties from "./use-buy-properties"
import useSoldProperties from "./use-sold-properties"

const useListingProperties = () => {
  const buy = useBuyProperties()
  const sold = useSoldProperties()
  const properties = buy.concat(sold)
  return properties
}

export default useListingProperties