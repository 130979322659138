import { useStaticQuery, graphql } from "gatsby"

const useTeamMembers = () => {
  const { allDatoCmsAgent } = useStaticQuery(graphql`
query TeamMembers {
  allDatoCmsAgent(
    filter: {hideForLocale: {ne: true}}
    sort: {position: ASC}
  ) {
    nodes {
      title
      position
      phone
      name
      jobTitle
      slug
      permalink
      id
      email
      biography {
        value
      }
      woollahra
      roseBay
      hideForLocale
      socialLinks {
        ...LinkWithTitleProps
      }
      profileImage {
        ...InternalAssetProps
      }
    }
  }
}
  `)

  return allDatoCmsAgent.nodes
}

export default useTeamMembers