import React from 'react'
import {Form} from "../../plugins/gatsby-plugin-atollon";
import {Loading} from './icons'

const FormComponent = (props) => {

  return (
    <Form
      schema={props.form}
      renderField={(field) => {
        if (field.type === "text" || field.type === "email" || field.type === "tel") {
          let defaultValue = null
          if (field.label === 'Property') defaultValue = props.property
          if (field.label === 'Agent') defaultValue = props.agent
          return (
            <div className='form__text'>
              <label htmlFor={field.id}>
                {field.label}
              </label>
              <input
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                type={(field.label === 'Property' || field.label === 'Agent') ? 'hidden' : field.type}
                defaultValue={defaultValue ? defaultValue : undefined}
                id={field.id}
                autoComplete={field.autoComplete}
                placeholder={field.placeholder}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "textarea") {
          return (
            <div className='form__textarea'>
              <label htmlFor={field.id}>
                  {field.label}
              </label>
              <textarea
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                placeholder={field.placeholder}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "select") {
          return (
            <div className='form__select'>
              <label htmlFor={field.id}>
                {field.label}
              </label>
              <select
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
                defaultValue=""
              >
                <option value="" disabled>{field.placeholder}</option>
                {field.options.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </select>
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        if (field.type === "radio") {
          return (
            <div className='form__radio-wrapper'>
              <label>
                {field.label}
              </label>
              <div className='form__radios'>
                {field.options.map((option) => (
                  <div className='form__radio'>
                    <input
                      id={option.id}
                      type="radio"
                      value={option.value}
                      ref={field.ref}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      name={field.name}
                      aria-required={field.required}
                      disabled={field.disabled}
                      aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                      aria-invalid={field?.invalid}
                    />
                    <label htmlFor={option.id}>{option.label}</label>
                  </div>
                ))}
              </div>
              {field.hasErrors && (
                  <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }

        if (field.type === "checkbox") {
          return (
            <div className={`form__checkbox${field.label === 'Property Management' ? ' form__checkbox--last' : ''}`}>
              <input
                type="checkbox"
                ref={field.ref}
                onBlur={field.onBlur}
                onChange={field.onChange}
                name={field.name}
                id={field.id}
                aria-required={field.required}
                disabled={field.disabled}
                aria-errormessage={field.hasErrors ? field.errors.join(", ") : undefined}
                aria-invalid={field?.invalid}
              />
              <label htmlFor={field.id}>
                {field.label}
              </label>
              {field.hasErrors && (
                <div role="alert">{field.errors.join(", ")}</div>
              )}
            </div>
          )
        }
        return (
          <div>field</div>
        )
      }}
      renderApiErrors={({ errors }) => (
        <div>{errors.join(", ")}</div>
      )}
      renderSubmitButton={({ children }) => ( <div className='form__submit'><button className='btn' type="submit">{children}</button></div>) }
      renderConfirmationMessage={({ message }) => (
        <div className='form__confirmation'>{message}</div>
      )}
      renderLoadingSpinner={() => <div className='form__loading'><Loading /></div>}
    />
  )
}

export default FormComponent
