import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Asset } from '../../../../plugins/gatsby-plugin-atollon'
import { graphql } from 'gatsby'

const carouselSettings = {
  showIndicators: false,
  showThumbs: false,
  showArrows: false,
  stopOnHover: false,
  emulateTouch: true,
  swipeable: true,
  infiniteLoop: true,
  showStatus: false,
  useKeyboardArrows: true,
}

const Gallery = ({ gallery }) => (
  <section className='gallery'>
    <div className='gallery__wrapper'>
      <Carousel { ...carouselSettings }>
        { gallery?.map((el, i) => (
          <Asset asset={ el } alt={ el.altText } key={ i }/>
        )) }
      </Carousel>
    </div>
  </section>
)

export default Gallery

export const query = graphql`
fragment GalleryProps on DatoCmsGallery {
  ... on DatoCmsGallery {
    id
    gallery {
      ...InternalAssetProps
    }
  }
}
`