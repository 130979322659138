import React from 'react'
import StructuredText from '../custom-structured-text-renderer'
import { graphql } from 'gatsby'

const BasicContent = ({ richTitle, richContent, borderBottom }) => {

  return (
    <>
      <section className={ `basic-content ${ borderBottom ? 'basic-content--border-bottom' : '' }` }>
        <div className='basic-content__inner'>
          { richTitle && <h2 className='basic-content__title'><StructuredText data={ richTitle }/></h2> }
          { richContent && <div className='basic-content__content'><StructuredText data={ richContent }/></div> }
        </div>
      </section>
    </>
  )
}

export default BasicContent

export const query = graphql`
fragment BasicContentProps on DatoCmsBasicContent {
  ... on DatoCmsBasicContent {
    id
    richContent {
      value
    }
    richTitle {
      value
    }
    borderBottom
  }
}
`
