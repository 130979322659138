import { useStaticQuery, graphql } from "gatsby"

const useBuyProperties =  () => {
  const { allDatoCmsProperty } = useStaticQuery(graphql`
    query LeaseProperties {
      allDatoCmsProperty(
        filter: {
          hideForLocale: {ne: true}
          market: {eq: "residential"}
          marketingLiveDate: {ne: null}
          for: {eq: "lease"}
        },
        sort: { marketingLiveDate: DESC }
      ) {
        nodes {
          id
          address
          slug
          permalink
          beds
          baths
          price
          priceSearch
          for
          allCarSpaces
          market
          heading
          landArea
          floorArea
          methodOfSale
          inspections {
            endDateTimeUtc
            startDateTimeUtc
          }
          images {
            primary {
              ...MediaItemProps
            }
          }
          agents {
            name
            email
            phone
            jobTitle
            profileImage {
              ...InternalAssetProps
            }
          }
          suburb {
            title
          }
          marketingLiveDate(formatString: "DD MMM yyyy h:mm A")
        }
      }
    }
  `)

  return allDatoCmsProperty.nodes 
}

export default useBuyProperties