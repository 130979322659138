import React from 'react'
import { graphql } from 'gatsby'
import { Link, MediaItem } from '../../../../plugins/gatsby-plugin-atollon'

import { Play, Sound, ScrollArrow } from '../../icons'
import LoadingScreen from '../../loading-screen'

import LogoText from '../../../assets/images/logo-text.svg'
import usePodcastQuery from '../../../hooks/use-podcast-query'

const Landing = ({ video, mobileVideo, videoUrl, mobileVideoUrl }) => {

  const podcast = usePodcastQuery()

  const [volume, setVolume] = React.useState(false)
  const [logotext, setLogotext] = React.useState('')

  const podcastItem = podcast?.nodes.length && podcast?.nodes[0]
  const podcastExternalLink = podcastItem.link[0].link?.length > 0 ? podcastItem.link[0].link[0].externalLink : false

  React.useEffect(() => {
    setTimeout(() => {
      setLogotext('in')
    }, 1000)
    setTimeout(() => {
      setLogotext('out')
    }, 5000)
  }, [])

  console.log({ video, mobileVideo, videoUrl, mobileVideoUrl })

  return (
    <>
      <LoadingScreen zoom={true} />
      <section className='landing'>
        <div className='landing__wrapper'>
          { video && mobileVideo && mobileVideoUrl && videoUrl &&
            <>
              <video className='mobile-video' src={mobileVideoUrl} poster={mobileVideo[0]?.thumbnail?.gatsbyImageData?.images?.fallback?.src} autoPlay loop muted playsInline />
              <video className='desktop-video' src={videoUrl} poster={video[0]?.thumbnail?.gatsbyImageData?.images?.fallback?.src} autoPlay loop muted playsInline />
            </>
          }
          <img className={`landing__logotext landing__logotext--${logotext}`} src={LogoText} alt='The Rubenstein Group' />
          <div className='landing__actions'>
            <div className='landing__inner'>
              { false &&
                <button className={`landing__sound ${volume ? 'active' : ''}`} onClick={() => this.setState({ volume: !volume })}>
                  <Sound color={`#FFFFFF`} />
                  <span>Sound</span>
                </button>
              }
              <div className={`landing__arrow ${logotext}`}>
                <p></p>
                <ScrollArrow color={`#FFFFFF`} />
              </div>
              <div className={`landing__podcast ${logotext}`}>
                <div className='landing__podcast-title'>
                  <span>Latest Podcast</span>
                  <span>{podcastItem.episode}</span>
                </div>
                <Play color={`#FFFFFF`} />
                <Link to={podcastExternalLink} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Landing

export const query = graphql`
  fragment LandingProps on DatoCmsLanding {
    ... on DatoCmsLanding {
      video {
        ...MediaItemProps
      }
      mobileVideo {
        ...MediaItemProps
      }
      videoUrl
      mobileVideoUrl
    }
  }
`
