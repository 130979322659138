import React, { Component } from 'react'
import { Asset } from '../../../../plugins/gatsby-plugin-atollon'
import { graphql } from 'gatsby'
import { Logo } from '../../icons'
import StructuredText from '../custom-structured-text-renderer'
import Fade from 'react-reveal/Fade'

class ThankYou extends Component {

  render() {

    let { subtitle, title, richContent, image, first_name } = this.props

    return (
      <section className='thank-you'>
        <div className='thank-you__header'>
          <div className='thank-you__outer'>
            <Logo color={`#FFFFFF`} />
            <button type='button' onClick={() => window && window.history.go(-1)} className='contact-form__close'>Close</button>
          </div>
        </div>
        <div className='thank-you__main'>
          <div className='thank-you__inner'>
            <Fade distance='40px' bottom>
              <div className='thank-you__content'>
                <h4>{subtitle}</h4>
                <h1>{title}.</h1>
                <StructuredText data={richContent} />
              </div>
              <div className='thank-you__image'>
                { image && <Asset asset={image} alt={title} /> }
              </div>
            </Fade>
          </div>
        </div>
      </section>
    )
  }
}

export default ThankYou

export const query = graphql`
fragment ThankYouProps on DatoCmsThankYou {
  ... on DatoCmsThankYou {
    subtitle
    title
    richContent {
      value
    }
    image {
      ...InternalAssetProps
    }
  }
}
`
