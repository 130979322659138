import { useStaticQuery, graphql } from "gatsby"

const useLayoutQuery = () => {
  return useStaticQuery(graphql`
    query {
      ...GlobalData
      datoCmsSiteOptionPage {
        office {
          title
          richAddress {
            value
          }
          email {
            ...LinkWithTitleProps
          }
          phone {
            ...LinkWithTitleProps
          }
        }
      }
      news: allDatoCmsArticle(
        filter: {
          category: {
            title: {
              eq: "News"
            }
          }
        }
        sort: { meta: { publishedAt: DESC } }
        limit: 1
      )
      {
        nodes {
          id
          title
          meta {
            publishedAt(formatString: "ddd DD MMMM")
          }
          featuredImage {
            ...InternalAssetProps
          }
          category {
            title
          }
          episode
          link {
            link {
              ... on DatoCmsExternalLink {
                externalLink
              }
            }
            title
          }
          video {
            url
          }
        }
      }
    }
  `)
}

export default useLayoutQuery