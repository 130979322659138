import React, { Component } from 'react'
import ArticleCard from '../../cards/article'
import usePostsQuery from "../../../hooks/use-posts-query"

const LatestMedia = () => {

  const posts = usePostsQuery()

  const news = posts.filter(el => el.category?.title === 'News')
  const podcast = posts.filter(el => el.category?.title === 'Podcast')
  const video = posts.filter(el => el.category?.title === 'Video')
  const instagram = posts.filter(el => el.category?.title === 'Instagram')

  return (
    <section className='latest-media'>
      <div className='latest-media__inner'>
        <div className='article__wrapper article__wrapper--large'>
          <ArticleCard {...news[0]} />
        </div>
        <div className='latest-media__grid'>
          <div className='article__wrapper'>
            <ArticleCard {...news[1]} />
          </div>
          <div className='article__wrapper'>
            <ArticleCard {...video[0]} />
          </div>
          <div className='article__wrapper'>
            <ArticleCard {...podcast[0]} />
          </div>
          <div className='article__wrapper'>
            { instagram && <ArticleCard {...instagram[0]} /> }
          </div>
        </div>
      </div>
    </section>
  )
}

export default LatestMedia