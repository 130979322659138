import React from 'react'
import parse from 'html-react-parser'
import { Projects } from '../../icons'
import { graphql } from 'gatsby'
import StructuredText from '../custom-structured-text-renderer'

const ProjectHeader = ({ title, richContent }) => (
  <section className='projects-header'>
    <div className='projects-header__inner'>
      <div className='projects-header__wrapper'>
        <Projects backgroundColor={ '#e9e5db' }/>
        { title && <h1>{ parse(title) }</h1> }
        { richContent && <StructuredText data={ richContent }/> }
      </div>
    </div>
  </section>
)

export default ProjectHeader

export const query = graphql`
fragment ProjectHeaderProps on DatoCmsProjectHeader {
  ... on DatoCmsProjectHeader {
    id
    title
    richContent {
      value
    }
  }
}
`