import React from 'react'
import useTeamMembers from '../../../hooks/use-team-members'
import TeamCard from '../../cards/team'
import SearchForm from './team-search'
import { graphql } from 'gatsby'

const TeamArchive = ({ title }) => {
  // Data
  const team = useTeamMembers()
  const offices = ['Woollahra', 'Rose Bay']

  // State
  const [list, setList] = React.useState(team);
  const [limit, setLimit] = React.useState(16);
  const [hover, setHover] = React.useState(false);

  let positions = []
  team?.forEach((el, i) => {
    positions.push(el.jobTitle)
  })
  
  // unique positions array
  positions = [...new Set(positions)]

  positions.sort()

  // Ref
  const load = React.createRef();

  // Events
  let timeout = null
  const onScroll = () => {
    let position = load?.current?.getBoundingClientRect()
    if (!position) return
    if (position.top >= 0 && position.bottom <= window.innerHeight) {
      if (!timeout) {
        //if (!this.timeout && this.state.length < this.state.list) {
        timeout = setTimeout(() => {
          setLimit(limit + 6)
          timeout = null
        }, 10)
      }
    } else {
      clearTimeout(timeout)
    }
  }

  // Update
  React.useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  })

  return (
    <>
      <section className='team-archive'>
        <div className='team-archive__outer'>
          <h1>{ title || 'Team' }</h1>
          <SearchForm
            list={team || []}
            set={setList}
            offices={offices}
            positions={positions}
          />
        </div>
        <div className='team-archive__inner'>
          <div className='team-archive__list'>
            { list?.slice(0, limit).map((el) => {
              el.cardProps = {
                onMouseEnter: () => setHover(el.id),
                onMouseLeave: () => setHover(false),
              }
              el.blur = hover !== el.id && hover !== false
              return (<TeamCard key={el.id} {...el} />)
            }) }
          </div>
          { list?.length === 0 && <p className='team-archive__no-results'>No listings at this time.</p> }
        </div>
      </section>
      <div ref={load} />
    </>

  )
}

TeamArchive.defaultProps = {

}

export default TeamArchive

export const query = graphql`
fragment TeamArchiveProps on DatoCmsTeamArchive {
  ... on DatoCmsTeamArchive {
    id
    title
  }
}
`