import React from 'react'
import StructuredText from '../custom-structured-text-renderer'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

const BasicPage = ({ title, richContent }) => {

  return (
    <>
      <section className='basic-page'>
        <div className='basic-page__inner'>
          { title && <h1 className='basic-page__title'>{parse(title)}</h1> }
          { richContent && <div className='basic-page__content'><StructuredText data={ richContent }/></div> }
        </div>
      </section>
    </>
  )
}

export default BasicPage

export const query = graphql`
fragment BasicPageProps on DatoCmsBasicPage {
  ... on DatoCmsBasicPage {
    id
    title
    richContent {
      value
    }
  }
}
`
