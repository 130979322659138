import { useStaticQuery, graphql } from "gatsby"

const useCommercialProperties =  () => {
  const { allDatoCmsProperty } = useStaticQuery(graphql`
    query CommercialProperties {
      allDatoCmsProperty(
        filter: {
          hideForLocale: {ne: true}
          market: {eq: "commercial"},
          price: {ne: "Off Market"},
          marketingLiveDate: {ne: null},
          for: {in: ["sale", "sold"]}
        },
        sort: { marketingLiveDate: DESC }
      ) {
        nodes {
          id
          address
          slug
          permalink
          beds
          baths
          price
          priceSearch
          for
          allCarSpaces
          market
          heading
          landArea
          floorArea
          methodOfSale
          soldDate(formatString: "DD MMM yyyy")
          inspections {
            endDateTimeUtc
            startDateTimeUtc
          }
          images {
            primary {
              ...MediaItemProps
            }
          }
          agents {
            name
            email
            phone
            jobTitle
            profileImage {
              ...InternalAssetProps
            }
          }
          suburb {
            title
          }
          marketingLiveDate(formatString: "DD MMM yyyy h:mm A")
        }
      }
    }
  `)

  return allDatoCmsProperty.nodes 
}

export default useCommercialProperties