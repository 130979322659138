import { useStaticQuery, graphql } from "gatsby"

const useCommercialLeaseProperties =  () => {
  const { lease, leased } = useStaticQuery(graphql`
    query CommercialLeaseProperties {
      lease: allDatoCmsProperty(
        filter: {
          hideForLocale: {ne: true}
          market: {eq: "commercial"},
          marketingLiveDate: {ne: null},
          for: {in: ["lease"]}
        },
        sort: { marketingLiveDate: DESC }
      ) {
        nodes {
          id
          address
          slug
          permalink
          beds
          baths
          price
          priceSearch
          for
          allCarSpaces
          market
          heading
          landArea
          floorArea
          methodOfSale
          inspections {
            endDateTimeUtc
            startDateTimeUtc
          }
          images {
            primary {
              ...MediaItemProps
            }
          }
          agents {
            name
            email
            phone
            jobTitle
            profileImage {
              ...InternalAssetProps
            }
          }
          suburb {
            title
          }
          marketingLiveDate(formatString: "DD MMM yyyy h:mm A")
        }
      }
      leased: allDatoCmsProperty(
        filter: {
          hideForLocale: {ne: true}
          market: {eq: "commercial"},
          marketingLiveDate: {ne: null},
          for: {in: ["leased"]}
        },
        sort: { marketingLiveDate: DESC }
      ) {
        nodes {
          id
          address
          slug
          permalink
          beds
          baths
          price
          priceSearch
          for
          allCarSpaces
          market
          heading
          landArea
          floorArea
          methodOfSale
          inspections {
            endDateTimeUtc
            startDateTimeUtc
          }
          images {
            primary {
              ...MediaItemProps
            }
          }
          agents {
            name
            email
            phone
            jobTitle
            profileImage {
              ...InternalAssetProps
            }
          }
          suburb {
            title
          }
          marketingLiveDate(formatString: "DD MMM yyyy h:mm A")
        }
      }
    }
  `)

  return [...lease.nodes, ...leased.nodes]
}

export default useCommercialLeaseProperties