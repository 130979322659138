import React from 'react'
import { Link, Asset } from '../../../plugins/gatsby-plugin-atollon'

const TeamCard = ({ name, email, profileImage, jobTitle, blur, cardProps, permalink }) => {

  return (
    <div className={ `card card--team ${ blur ? 'card--blur' : '' }` } { ...cardProps }>
      <Link to={ permalink } className='card__image'>
        { profileImage && <Asset asset={ profileImage } alt={ name }/> }
      </Link>
      <div className='card__content'>
        <Link to={ permalink } className='card__content-title'>
          <h4>{ name }</h4>
          <p>{ jobTitle }</p>
        </Link>
        <p className='card__email'><Link to={ `mailto:${ email }` }>Email</Link></p>
      </div>
    </div>
  )
}

export default TeamCard
