import React from 'react'
import { Asset, Link, MediaItem } from '../../../../plugins/gatsby-plugin-atollon'
import { Arrow } from '../../icons'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'

const ImageCta = ({ image, title, subtitle, button, type = '' }) => {
  return (
    <section className='image-cta'>
      { image?.media?.length > 0 ?
        <div className='image-cta__bg'>
          <MediaItem item={ image } alt={ title }/>
        </div> :
        <div className='image-cta__bg'>
          <Asset asset={ image } alt={ title }/>
        </div>
      }

      { type === 'property' &&
        <div className='image-cta__wrapper'>
          <div className='image-cta__inner image-cta__inner--mobile'>
            { button?.length > 0 && <Link className='image-cta__title' to={ button[0].link[0].externalLink ? button[0].link[0].externalLink : button[0].link[0].internalLink.permalink }><Arrow
              color={ `#21242F` }/> Next: { parse(button[0].title) }</Link> }
            <div>
              <Link className='image-cta__button' to={ `/buy/` }>
                Back to Listings
              </Link>
            </div>
          </div>
          <div className='image-cta__inner image-cta__inner--desktop'>
            { title && <span className='image-cta__title'>{ title }</span> }
            <div>
              { subtitle && <span className='image-cta__subtitle'>{ subtitle } —</span> }
              { button?.length > 0 && <Link className='image-cta__button' to={ button[0].link[0].externalLink ? button[0].link[0].externalLink : button[0].link[0].internalLink.permalink }>{ parse(
                button[0].title) }</Link> }
            </div>
          </div>
        </div>
      }

      { type !== 'property' &&
        <div className='image-cta__wrapper'>
          <div className='image-cta__inner'>
            <span className='image-cta__title'>{ title }</span>
            <div>
              <span className='image-cta__subtitle'>{ subtitle } —</span>
              { button?.length > 0 && <Link className='image-cta__button' to={ button[0].link[0].externalLink ? button[0].link[0].externalLink : button[0].link[0].internalLink.permalink }>{ parse(
                button[0].title) }</Link> }
            </div>
          </div>
        </div>
      }
    </section>
  )
}

export default ImageCta

export const query = graphql`
fragment ImageCtaProps on DatoCmsImageCta {
  ... on DatoCmsImageCta {
    id
    title
    subtitle
    button {
        ...LinkWithTitleProps
      }
    image {
      ...InternalAssetProps
    }
  }
}
`
