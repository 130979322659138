import React from 'react'
import parse from 'html-react-parser'
import { Arrow } from '../../icons'
import { Link } from '../../../../plugins/gatsby-plugin-atollon'
import { graphql } from 'gatsby'
import StructuredText from '../custom-structured-text-renderer'

const QuoteStat = ({ richQuote, stats, richContent, location, name }) => {

  // funciton when clicked smooth scrolls down to #enquire div
  const scrollToEnquire = () => {
    const el = document.getElementById('enquire')
    el.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <section className='quote-stats'>
      <div className='quote-stats__inner'>
        <div className='quote-stats__left'>
          <div className='quote-stats__quote'>
            { richQuote && <StructuredText as='div' data={ richQuote }/> }
            { name && <span>{ parse(name) }</span> }
          </div>
          <ul>
            { stats && stats.map((el, i) => (
              <li key={ i }>
                { el.label && <span>{ parse(el.label) }</span> }
                { el.value && <h4>{ parse(el.value) }</h4> }
              </li>
            )) }
          </ul>
        </div>
        <div className='quote-stats__right'>
          { richContent && <div className='quote-stats__content'><StructuredText data={ richContent }/></div> }
          <ul>
            { location?.length > 0 && <li><Link to={ location[0].link[0].externalLink ? location[0].link[0].externalLink : location[0].link[0].internalLink.permalink }>{ parse(
              location[0].title) } <Arrow color={ `#21242F` }/></Link></li> }
            <li>
              <button onClick={ () => scrollToEnquire() }>Enquire Now <Arrow color={ `#21242F` }/></button>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default QuoteStat

export const query = graphql`
fragment QuoteStatProps on DatoCmsQuoteStat {
  ... on DatoCmsQuoteStat {
    id
    name
    richQuote {
      value
    }
    richContent {
      value
    }
    stats {
      label
      value
    }
    location {
      ...LinkWithTitleProps
    }
  }
}
`