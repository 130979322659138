import { useStaticQuery, graphql } from "gatsby"

const useSoldProperties =  () => {
  const { allDatoCmsProperty } = useStaticQuery(graphql`
    query SoldProperties {
      allDatoCmsProperty(
        filter: {
          hideForLocale: {ne: true}
          price: {ne: "Off Market"}
          market: {eq: "residential"}
          marketingLiveDate: {ne: null}
          for: {eq: "sold"}
        },
        sort: { soldDate: DESC }
      ) {
        nodes {
          id
          address
          slug
          permalink
          beds
          baths
          price
          priceSearch
          for
          allCarSpaces
          market
          heading
          landArea
          floorArea
          methodOfSale
          soldDate(formatString: "DD MMM yyyy")
          images {
            primary {
              ...MediaItemProps
            }
          }
          agents {
            name
            email
            phone
            jobTitle
            profileImage {
              ...InternalAssetProps
            }
          }
          suburb {
            title
          }
          auctionDateTime(formatString: "DD MMM yyyy h:mm A")
        }
      }
    }
  `)

  return allDatoCmsProperty.nodes 
}

export default useSoldProperties