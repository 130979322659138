import React, { useState } from 'react'
import { DownArrow } from '../../icons'
import { Link, Asset } from '../../../../plugins/gatsby-plugin-atollon'
import { graphql } from 'gatsby'

const ProjectSelector = ({selectProjects}) => {
  const results = selectProjects
  const [list, setList] = useState(results)
  const [filter, setFilter] = useState('View All')
  const [filterActive, setFilterActive] = useState(false)

  // create a filter function to filter projects by status
  const filterProjects = (status) => {
    if (status === 'View All') {
      setList(results)
      setFilter(status)
      setFilterActive(false)
    } else {
      setList(results.filter(el => el.projectStatus === status))
      setFilter(status)
      setFilterActive(false)
    }
  }

  return (
    <>
      <section className='projects-filter'>
        <div className='projects-filter__inner'>
          <button className='projects-filter__toggle' onClick={ () => setFilterActive(!filterActive) }>
            <span>{ filter }</span>
            <DownArrow/>
          </button>
          <ul className={ filterActive ? 'active' : '' }>
            <li>
              <button onClick={ () => filterProjects('View All') }>View All</button>
            </li>
            <li>
              <button className={ filter === 'Current' ? 'active' : '' } onClick={ () => filterProjects('current') }>Current</button>
            </li>
            <li>
              <button className={ filter === 'Coming Soon' ? 'active' : '' } onClick={ () => filterProjects('coming Soon') }>Coming Soon</button>
            </li>
            <li>
              <button className={ filter === 'Sold' ? 'active' : '' } onClick={ () => filterProjects('sold') }>Sold</button>
            </li>
          </ul>
        </div>
      </section>
      <section className='featured featured--notable featured--projects'>
        { list.length > 0 && list.map((el, i) => (
          <div className='featured__item' key={ i }>
            <div className='featured__wrapper'>
              <div className='featured__header'>
                <div className='featured__inner'>
                  <div className='featured__number'>0{ i + 1 }</div>
                  <div className='featured__address'>{ el.title }</div>
                  <div className='featured__meta'>
                    <Link to={ el.permalink }>View Project</Link>
                  </div>
                </div>
              </div>
              <div className='featured__image-wrapper'>
                <div className='featured__inner'>
                  <div className='featured__image'>
                    <span className='featured__tag'>{ el.projectStatus }</span>
                    <Link to={ el.permalink }>
                      { el.featuredImage && <Asset asset={ el.featuredImage } alt={ el.title }/> }
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )) }
        { list?.length === 0 &&
          <p className='featured__no-results'>
            No projects match your search. Please try again.
          </p>
        }
      </section>
    </>
  )
}

export default ProjectSelector

export const query = graphql`
fragment ProjectSelectorProps on DatoCmsProjectSelector {
  ... on DatoCmsProjectSelector {
    id
    selectProjects {
      slug
      position
      permalink
      projectStatus
      meta {
        publishedAt(formatString: "DD.MM.YY")
      }
      id
      featuredImage {
        ...InternalAssetProps
      }
      address
      title
    }
  }
}
`


