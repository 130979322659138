import React, { Component } from 'react'
import { globalHistory } from '@reach/router'
import Select from 'react-select'
import { Search } from './icons'

const categories = [
  { label: 'View: All', value: '' },
  { label: 'News', value: 'news' },
  { label: 'Podcast', value: 'podcast' },
  { label: 'Video', value: 'video' },
  { label: 'Real Estate Diaries', value: 'real-estate-diaries' },
  { label: 'Instagram', value: 'instagram' },
]

class SearchMedia extends Component {

  state = {
    search: '',
    cat: null,
  }

  componentDidMount() {
    this.urlChange()
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') this.urlChange()
    })
  }

  componentWillUnmount() {
    document.removeEventListener('change', this.urlChange)
  }

  urlChange = () => {
    if (window.location?.search !== '') {
      let state = this.state
      let queryStr = window.location?.search,
        queryArr = queryStr.replace('?', '').replace(/\+/g, ' ').split('&'),
        queryParams = []

      for (let i = 0; i < queryArr.length; i++) {
        let qArr = queryArr[i].split('=')
        if (qArr[1] === '') continue
        queryParams.push({
          name: qArr[0],
          value: qArr[1],
        })
        if (qArr[0] === '') continue
        let option = {
          currentTarget: {
            name: qArr[0],
            value: qArr[1],
          }
        }
        state[qArr[0]] = decodeURI(qArr[1])
        this.handleSelectChange(option, { action: 'mount' })
      }
    }
  }

  handleSelectChange = (option, field) => {
    let state = {}
    switch (field && field.action) {
      case 'select-option':
        state[field.name] = option
        break
      case 'mount':
        let { name, value } = option.currentTarget
        let item = this[name]?.props?.options?.find(el => el.value == value)
        if (item) {
          state[name] = item
        }
        break
      default:
        state[option.currentTarget.name] = option.currentTarget.value
        break
    }
    this.setState(state, this.updateList)
  }

  updateList = () => {
    const { list, set } = this.props
    const { search, cat } = this.state

    let results = {}

    if (list) {
      results = list.filter((el, i) => {
        const { title, category } = el
        if (cat?.value === '') return true
        if (cat && cat.label !== category?.title) return false
        if (search && title.replace(/,/g, '').toLowerCase().indexOf(search) === -1) return false

        return true
      })
    }
    set(results)
    this.props.setCategory(cat.value)
  }

  render() {
    let { cat } = this.state

    return (
      <section className='search search--media'>
        <div className="search__inner">
          <form className='search__form form'>
            <div className="form__row form__row--search">
              <input className="form__input" type="text" name="search" value={this.state.search} placeholder='Keyword Search' onChange={this.handleSelectChange} />
              <button type='submit'>
                <Search color='#21242F' />
              </button>
            </div>
            <div className="form__row form__row--dropdowns">
              <div className='form__select form__select--category'>
                <Select
                  options={categories}
                  onChange={this.handleSelectChange}
                  openMenuOnFocus={true}
                  id="cat"
                  name="cat"
                  value={cat}
                  placeholder="View: All"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  ref={node => this.cat = node}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
    )
  }
}

SearchMedia.defaultProps = {
  list: [],
  set: () => { },
}

export default SearchMedia